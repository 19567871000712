<template>
  <div class="base-tag-item">
    <div
      class="tag"
      v-if="!editing"
      :class="{ removable: removable, editable: editable }"
      :style="{ backgroundColor: tag.color, color: textColor }"
      @click="addClick"
      data-test="tag"
    >
      <div class="tag-name">{{ tag.name }}</div>
      <div
        class="remove"
        v-if="removable"
        :style="{ backgroundColor: tag.color }"
        @click="removeClick"
        data-test="remove"
      >
        <font-awesome-icon :icon="timesIcon" />
      </div>
    </div>
    <base-text-box
      v-else
      v-model="tagName"
      :maxlength="15"
      :styles="textBoxStyles"
    />
    <button
      class="square-button"
      v-if="editable && lookOnlyFlg === 0"
      :disabled="error || waitFlg"
      @click="editName"
      data-test="square-button"
    >
      <font-awesome-icon :icon="editing ? checkIcon : pencilIcon" />
    </button>
    <button
      class="square-button"
      v-if="editable && lookOnlyFlg === 0"
      :disabled="editing || waitFlg"
      @click="$emit('delete', tag.id)"
      data-test="square-button"
    >
      <img
        src="@/assets/images/trash_orange.png"
        width="15px"
        height="18px"
        alt="trash-orange"
      />
    </button>
    <div
      class="color-chart-button square-button"
      v-if="editable && lookOnlyFlg === 0"
      @click="isActive = !isActive"
      data-test="square-button"
    >
      <img
        src="@/assets/images/box_menu_pumpkin_32px.png"
        class="box-menu-icon"
        width="20px"
        height="20px"
        alt="box-menu-pumpkin"
      />
    </div>
    <transition name="fade">
      <base-color-chart
        class="chart"
        v-show="isActive"
        :colorSets="colorSets"
        :value="tag.color"
        @click="inputColor"
      />
    </transition>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import BaseColorChart from '@/components/parts/atoms/BaseColorChart'
import ColorMethods from '@/components/mixins/ColorMethods'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox.vue'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faTimes,
  faCheck,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons'
import {
  achromaticColors,
  reds,
  oranges,
  yellows,
  yellowGreens,
  greens,
  turquoises,
  lightBlues,
  blues,
  navyBlues,
  purples,
  pinks,
  redPinks
} from '@/utils/color_library'

export default {
  name: 'BaseTagItem',

  components: { BaseColorChart, BaseTextBox, FontAwesomeIcon },

  mixins: [ColorMethods],

  props: {
    tag: { type: Object, required: true },
    tags: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false },
    removable: { type: Boolean, default: false },
    color: { type: String, default: '#ef6c00' },
    waitFlg: { type: Boolean }
  },

  data() {
    return {
      isActive: false,
      editing: false,
      tagName: {},
      timesIcon: faTimes,
      checkIcon: faCheck,
      pencilIcon: faPencilAlt,
      textBoxStyles: {
        fontSize: '13px',
        height: '30px',
        minWidth: '85px',
        maxWidth: '210px',
        position: 'relative',
        borderRadius: '6px'
      }
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    colorSets() {
      const hue = 4
      return Array(
        [
          achromaticColors,
          reds,
          oranges,
          yellows,
          yellowGreens,
          greens,
          turquoises,
          lightBlues,
          blues,
          navyBlues,
          purples,
          pinks,
          redPinks
        ].map(c => c[hue])
      )
    },
    textColor() {
      return this.mixinAdaptTextColor(this.tag.color)
    },
    error() {
      const tagNames = this.tags
        .filter(v => v.id !== this.tag.id)
        .map(v => v.name)
      return this.tagName === ''
        ? 'タグ名を入力してください'
        : /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(this.tagName)
        ? '使用できない文字が含まれています'
        : this.tagName.length > 15
        ? '15文字以内で入力して下さい'
        : tagNames.includes(this.tagName)
        ? '既に使用されているタグ名です'
        : false
    }
  },

  created() {
    this.tagName = this.tag.name
  },

  methods: {
    addClick() {
      if (this.editable && !this.editing) this.$emit('click', this.tag.id)
    },
    removeClick() {
      if (!this.editable) this.$emit('click', this.tag.id)
    },
    inputColor(color) {
      this.$emit('update', { ...this.tag, color: color })
      this.isActive = false
    },
    editName() {
      if (this.editing === true) {
        this.$emit('update', { ...this.tag, name: this.tagName })
        this.editing = false
      } else {
        this.editing = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-tag-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 5px;
  > .tag {
    font-size: 13px;
    height: 24px;
    min-width: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 6px;
    > .tag-name {
      padding: 0px 6px;
    }
    &.removable {
      > .tag-name {
        padding: 0px 23px 0px 6px;
        cursor: default;
      }
      > .remove {
        padding: 0 6px;
        position: absolute;
        right: 1px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &.editable {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &:active {
        position: relative;
        top: 2px;
        height: 22px;
      }
    }
  }
  > .square-button {
    height: 30px;
    width: 30px;
    font-size: 13px;
    background-color: white;
    box-sizing: border-box;
    padding: auto;
    border: solid 2px #{$light-grey};
    border-radius: 6px;
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$light-grey};
      color: #{$brown-gray};
    }
    &:hover {
      opacity: 0.8;
      background-color: #{$plus_ee_gray};
    }
    &:active {
      position: relative;
      top: 2px;
      height: 28px;
    }
  }
  > .color-chart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    > .box-menu-icon {
      width: 20px;
      height: 20px;
    }
  }
  .chart {
    z-index: 20;
  }
  > .error {
    width: 100%;
    color: #{$tomato};
    font-size: 12px;
  }
  .fade-leave-active,
  .fade-enter-active {
    transition: opacity 0.5s;
  }
  .fade-leave-to,
  .fade-enter {
    opacity: 0;
  }
}
</style>
