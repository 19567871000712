<template>
  <div class="base-back-link" @click="handleClick">
    <span>＜</span>{{ text }}
  </div>
</template>

<script>
export default {
  name: 'BaseBackLink',
  props: {
    text: { type: String, default: '一覧に戻る' }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-back-link {
  min-width: 120px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  > span {
    color: #{$pumpkin};
    margin-right: 12px;
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}
</style>
