<template>
  <div class="show-data-list-row-value" data-e2e="show-data-list-row-value">
    <div
      class="color-plate"
      v-if="colorFlg"
      :style="{ backgroundColor: propertyValue }"
    />
    <div class="text" v-if="propertyKey !== 'tags'">
      <span>{{ propertyValue }}</span>
      <span class="red-text" v-if="redText">{{ redText }}</span>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ShowDataListRowValue',

  props: {
    propertyKey: { type: String },
    propertyValue: { type: [Number, String, Array] },
    redText: { type: String }
  },

  data() {
    return { colorFlg: false }
  },

  created() {
    const VALID_COLOR_CODE_REGEX = /^#[a-fA-F0-9]{6}$/
    this.propertyKey === 'color' &&
    VALID_COLOR_CODE_REGEX.test(this.propertyValue)
      ? (this.colorFlg = true)
      : (this.colorFlg = false)
  }
}
</script>

<style lang="scss" scoped>
.show-data-list-row-value {
  display: flex;
  width: 100%;
  font-size: 13px;
  text-align: left;
  color: #{$greyish-brown};
  > .color-plate {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    margin-right: 6px;
    border: solid 1px black;
    border-radius: 4px;
  }
  > .text {
    flex: 1;
    width: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    > .red-text {
      color: #de3d3d;
    }
  }
}
</style>
