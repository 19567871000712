<template>
  <div class="show-data-list">
    <show-data-list-row
      class="row"
      v-for="(property, index) in properties"
      :key="index"
      :redText="property.redText"
      v-bind="property"
    />
  </div>
</template>

<script>
import ShowDataListRow from '@/components/parts/molecules/ShowDataListRow.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ShowDataList',

  components: {
    ShowDataListRow
  },

  props: {
    displayKeys: { type: Array, default: () => [] },
    keys: { type: Array, default: () => [] },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById'
    }),
    properties() {
      return this.displayKeys.map((data, i) => {
        const key = this.keys[i]
        const propertyValue =
          key === 'jobId'
            ? this.getMasterDatum('jobs', this.data[key]).name
            : key === 'prefectureId'
            ? this.getMasterDatum('prefectures', this.data[key]).name
            : this.data[key]
        return {
          keyData: { displayKey: data },
          valueData: {
            propertyKey: key,
            propertyValue
          },
          redText: this.data.redTextData ? this.data.redTextData[key] : ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.show-data-list {
  > .row ~ .row {
    margin-top: 25px;
  }
}
</style>
