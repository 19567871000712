<template>
  <div class="show-data-list-row">
    <show-data-list-row-key class="key" v-bind="keyData" />
    <show-data-list-row-value
      class="value"
      v-bind="valueData"
      :redText="redText"
    >
      <div class="tags wrap" v-if="valueData.propertyKey === 'tags'">
        <div v-for="tag in valueData.propertyValue" :key="tag.id">
          <base-tag-item
            :tag="tag"
            :styles="{ cursor: 'pointer !important' }"
          />
        </div></div
    ></show-data-list-row-value>
  </div>
</template>

<script>
import ShowDataListRowKey from '@/components/parts/atoms/ShowDataListRowKey.vue'
import ShowDataListRowValue from '@/components/parts/atoms/ShowDataListRowValue.vue'
import BaseTagItem from '../atoms/BaseTagItem.vue'

export default {
  name: 'ShowDataListRow',

  components: {
    ShowDataListRowKey,
    ShowDataListRowValue,
    BaseTagItem
  },

  props: {
    keyData: { type: Object },
    valueData: { type: Object },
    redText: { type: String }
  }
}
</script>

<style lang="scss" scoped>
.show-data-list-row {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  > .key {
    width: 240px;
  }
  > .value {
    flex: 1;
    display: flex;
    align-items: center;
    > .tags {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 6px 5px;
    }
  }
}
</style>
