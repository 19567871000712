<template>
  <div class="show-data-list-row-key">
    <span>{{ displayKey }}</span>
  </div>
</template>

<script>
export default {
  name: 'ShowDataListRowKey',

  props: {
    displayKey: { type: String }
  }
}
</script>

<style lang="scss" scoped>
.show-data-list-row-key {
  line-height: 22px;
  font-size: 15px;
  text-align: left;
  color: #{$greyish-brown};
}
</style>
